import { getAccountId } from './common';
import getAxios from './common/header';
import { getApi } from './common/apis';

export default class QuestionBankService {
    async updateQuestion(payload, qsid, categoryId) {
        let payloadData = {
            fieldType: payload.field_type,
            dataType: payload.data_type,
            displayName: payload.display_name,
            fieldId: payload.id,
            categoryId: categoryId,
            questionSetId: qsid,
            fieldName: payload.field_name,
            description: payload.description,
            placeholder: payload.placeholder,
            attachmentRequired: payload.attachment_required,
            errorMessage: payload.error_message,
        };

        let response = await getAxios().put(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/question-bank/question`,
            JSON.parse(JSON.stringify(payloadData))
        );
        return response;
    }

    async updateCategory(payload, qsid) {
        let payloadData = {
            id: payload.category_id,
            name: payload.category_name,
            questionSetId: qsid,
        };

        let response = await getAxios().put(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/question-bank/category`,
            JSON.parse(JSON.stringify(payloadData))
        );
        return response;
    }

    async saveQuestionsOrder(payload, qsid, categoryId) {
        let payloadData = {
            questionSetId: qsid,
            categoryId: categoryId,
            questions: payload,
        };

        let response = await getAxios().put(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/question-bank/question-order`,
            JSON.parse(JSON.stringify(payloadData))
        );
        return response;
    }

    async saveCategoriesOrder(payload) {
        let payloadData = [];

        payload.forEach((category) => {
            payloadData.push({
                category_id: category.category_id,
                category_position: category.category_position,
            });
        });

        let response = await getAxios().put(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/question-bank/category-order`,
            JSON.parse(JSON.stringify(payloadData))
        );
        return response;
    }
}
